import { Grid, Typography } from "@mui/material";
import React, { JSXElementConstructor, ReactElement } from "react";

type Item = {
    name: string;
    image: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}

type Props = {
    key: number;
    item: Item;
}

const CarouselItem = (props: Props) => {

    return (<>
        {!!props.item &&
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography sx={{
                        color: 'white'
                    }} m={4} variant="h4">{props.item.name}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', height: '700px' }}>
                    {props.item.image}
                </Grid>
            </Grid>
        }
    </>)
}

export default CarouselItem;
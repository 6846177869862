import { Paper, Grid, Typography, Button, Link } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import CarouselItem from "./CarouselItem";
import demo1 from "../../images/demo-ss-1.png";
import demo2 from "../../images/demo-ss-2.png";
import demo3 from "../../images/demo-ss-3.png";
import demo4 from "../../images/demo-ss-4.png";

const carousel_items = [
  {
    name: "Upload your Desktop PC to get an appraisal.",
    image: <img style={{ boxShadow: '5px 5px 5px black', borderRadius: '5px' }} src={demo1} height={'100%'} />,
  },
  {
    name: "Go crazy with analytics, which breakdown your components bit-by-bit.",
    image: <img style={{ boxShadow: '5px 5px 5px black', borderRadius: '5px' }} src={demo2} height={700} />,
  },
  {
    name: "Share your appraisal with others wherever you want to sell your parts.",
    image: <img style={{ boxShadow: '5px 5px 5px black', borderRadius: '5px' }} src={demo3} height={450} width={800} />,
  },
  {
    name: "Get deep insights into all components to plan your upgrades.",
    image: <img style={{ boxShadow: '5px 5px 5px black', borderRadius: '5px' }} src={demo4} height={700} />,
  },
];

const AppraisalFeatures = () => {

  const [active_carousel_item, set_active_carousel_item] = useState<number>(0);
  function change_carousel_item(new_carousel_item: number) {
    if (new_carousel_item >= carousel_items.length) {
      set_active_carousel_item(0);
    } else {
      set_active_carousel_item(new_carousel_item);
    }
  }

  return (
    <main>
      <Paper sx={{ backgroundColor: "#39B54A", pt: 4, pb: 4, pl: 4, pr: 4, mb: 8 }}>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#fff" }}
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              <i>
                A BitLot appraisal can save you weeks on the selling process.
              </i>
            </Typography>
          </Grid>
          <Grid item xs={10} lg={8} sx={{ margin: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  disabled={active_carousel_item === 0}
                  sx={
                    {
                      fontSize: 16,
                      borderColor: '#fff',
                      '&:disabled': {
                        color: '#fff',
                      },
                      maxWidth: 200,

                    }
                  }
                  variant="contained"
                  onClick={() => change_carousel_item(0)}

                >
                  Visualize your PC
                </Button>
              </Grid>
              <Grid item sm={3} xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  disabled={active_carousel_item === 1}
                  sx={
                    {
                      fontSize: 16,
                      borderColor: '#fff',
                      '&:disabled': {
                        color: '#fff',
                      },
                      maxWidth: 200,
                    }
                  }
                  variant="contained"
                  onClick={() => change_carousel_item(1)}

                >
                  Get novel insights
                </Button>
              </Grid>
              <Grid item sm={3} xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  disabled={active_carousel_item === 2}
                  sx={
                    {
                      fontSize: 16,
                      borderColor: '#fff',
                      '&:disabled': {
                        color: '#fff',
                      },
                      maxWidth: 200,
                    }
                  }
                  variant="contained"
                  onClick={() => change_carousel_item(2)}

                >
                  Prove your build&apos;s value
                </Button>
              </Grid>
              <Grid item sm={3} xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  disabled={active_carousel_item === 3}
                  sx={
                    {
                      fontSize: 16,
                      borderColor: '#fff',
                      '&:disabled': {
                        color: '#fff',
                      },
                      maxWidth: 200,
                    }
                  }
                  variant="contained"
                  onClick={() => change_carousel_item(3)}

                >
                  Component analytics
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{
              height: '900px'
            }}>
              <Carousel
                // indicatorIconButtonProps={{style: {width: '200px', borderRadius: 0, fill: '100%'}}}
                // indicatorContainerProps={{style: {fill: '100%', backgroundColor: 'black'}}}
                onChange={(now) => change_carousel_item(now ?? 0)}
                index={active_carousel_item}
                indicatorContainerProps={{ style: { display: 'none' } }}
                interval={10000}
              >
                {
                  carousel_items.map((item, i) => <CarouselItem key={i} item={item} />)
                }
              </Carousel>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#fff" }}
              component="h5"
              variant="h5"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Post your appraised components and builds on eBay and Facebook
              Marketplace or share them with friends and family.
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              component="h5"
              variant="h5"
              align="center"
              color="textPrimary"
            >
              Take a look at our live demo <Link underline={'none'} color={'secondary'} href='https://bitlot.app/builds/f40cf584-30a2-4e99-898c-1d32e6227e76'>here.</Link>
            </Typography>
          </Grid>

        </Grid>
      </Paper>
    </main>
  );
};

export default AppraisalFeatures;

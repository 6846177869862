import { Paper, Grid, Typography, Button } from "@mui/material";
import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

const AppraisalTimeline = () => {
  return (
    <main>
      <Paper
        sx={{
          backgroundColor: "rgba(57, 181, 74, 0.04)",
          pt: 4,
          pb: 4,
          pl: 4,
          pr: 4,
          mb: 8
        }}
      >
        <Grid container spacing={4} sx={{ color: "#fff" }}>
          <Grid item xs={12}>
            <Typography
              component="h3"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Here&rsquo;s how it works:
            </Typography>
            <Timeline sx={{ color: "#39B54A" }} position={"alternate-reverse"}>
              <TimelineItem sx={{ color: "#39B54A" }}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                </TimelineSeparator>
                <TimelineContent variant="h6">
                  1. Tell us about your build and how you&apos;ve used it{" "}
                  <Button>Get started here!</Button>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                </TimelineSeparator>
                <TimelineContent variant="h6">
                  2. Get appraisal tokens to appraise your build
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                </TimelineSeparator>
                <TimelineContent variant="h6">
                  3. Within 24 hours, we get back to you with your PC&apos;s
                  valuation and stats
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                </TimelineSeparator>
                <TimelineContent variant="h6">
                  4.{" "}
                  <ul>
                    <li>Sell your build knowing what it&apos;s worth</li>
                    <li>Make informed upgrades</li>
                    <li>Get your money&apos;s worth on your next build</li>
                  </ul>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
};

export default AppraisalTimeline;
